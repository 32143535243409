import { put, takeLatest } from '@redux-saga/core/effects'
import { graphQL } from '../lib/fetch/graphql'
import dayjs from 'dayjs'
import { sessionAction } from '../lib/localUtil'
import { checkAllJson, checkJsonByFiles } from '../lib/fetch/cache'

function quaryMaker({query_type, startCursor, eventDate, query, last}){
  const beforeQuery = startCursor ? 'before: $startCursor, ' : '';
  let params = { beforeQuery };

  if(query_type=== "news_by_date"){
    const startCursorQuery = startCursor ? '($startCursor: String)' : '';
    let date = new Date(eventDate);
    let date2 = new Date(eventDate);
    // 該当月の1日
    date.setDate(1);
    const startDate = dayjs(date).format("YYYY-MM-DD");
    const starts = startDate.split("-").map(st=>parseInt(st));
    // 翌月の1日
    date2.setMonth(date2.getMonth() + 1);
    date2.setDate(1);
    const endDate = dayjs(date2).format("YYYY-MM-DD");
    const ends = endDate.split("-").map(st=>parseInt(st));
    params = {...params, startCursorQuery, starts, ends, last};
  }
  else if(query_type==="post_by_date"){
    const startCursorQuery = startCursor ? '$startCursor: String, ' : '';
    params = {...params, startCursorQuery, typeQuery:"", last};
  }

  return query && query(params);
}

const PARAMS = {
  post_by_date:{
    data_type:'posts',
    after_action:'SET_CAL_EVENTS'
  },
  news_by_date:{
    data_type:'newses',
    after_action:'SET_CAL_NEWSES'
  }
}

function*SAGA_BY_EVENT_DATE(action){
  const {query_type, query, eventDate, startCursor, last=20, async_count=0} = action.payload;
  const param = PARAMS[query_type];
  if(!param) return;
  if(async_count===0) yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});

  const ss_key = encodeURIComponent(`${query_type}${eventDate}${startCursor || ''}`);
  const ym = eventDate.slice( -1 ) ==='-' ? eventDate.slice(0, -1) : eventDate;

  if(!sessionStorage.getItem(ss_key) && !startCursor){
    //セッションにない場合、分割ロードではない場合、キャッシュファイルがあるかを試す、eventsやnewsesのキャッシュは一括ファイルのみなので
    const cache_name = query_type==='news_by_date' ? `newses_${ym}` : `events_${ym}`;
    const ec = yield checkAllJson({cache_name});
    yield
    if(ec.data && ec.ym===ym) {
      const items = ec.data.posts || ec.data.newses;
      yield put({type:param.after_action, payload:{eventDate, items}});
      yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
      console.log("BY_EVENT_DATE>OK:C");
      return;
    }
  }

  if(window.run_mode===1){
    console.log("skip GQL! bye.");
    return;
  }
  //セッションもキャッシュもない場合はWpQueryにアクセスする
  try{
    const postQuery = quaryMaker({query_type, startCursor, eventDate, query, last});
    const { data } = yield graphQL({key:ss_key, query:postQuery, variables:{startCursor, eventDate}});
    console.log("call GQL");
    yield
    if(data && data[param.data_type]){
      sessionAction('set', ss_key, JSON.stringify(data));
      const d = data[param.data_type];

      yield put({type:param.after_action, payload:{eventDate, items:d}});
      yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});

      if(async_count < 9 && d.pageInfo && d.pageInfo.hasPreviousPage && d.pageInfo.startCursor){
        const updated_async_count = async_count+1; //asyncで反復する回数は５回以内とする！（エラー対処）
        const startCursor = d.pageInfo.startCursor;
        yield put({type:'SAGA_BY_EVENT_DATE', payload:{query_type, query, eventDate, startCursor, async_count:updated_async_count}});
      }
    }
  } catch(error){
    console.log("ERROR:SAGA_BY_EVENT_DATE", error);
  }

  //念のため最後にもfalseにする
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}

function*SAGA_GETS(action){
  const {query, key} = action.payload;
  console.log("SAGA_GETS",key);
  let post_data = null;
  const ec = yield checkAllJson({cache_name:key});
  if(ec && ec.data) {
    post_data = ec.data;
  } else if(window.run_mode !==1) {
    yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
    try {
      const {data} = yield graphQL({query, key});
      if(data) post_data = data;
    }catch(error) {
      console.log("ERROR:SAGA_GETS", error);
    }
    yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
  }

  if(post_data){
    yield put({type:"SET_OBJ", payload:post_data});
    sessionAction('set', key, JSON.stringify(post_data));
  }
}

function*SAGA_GET_BYID(action){
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
  const {id, query, type} = action.payload;
  const ss_key = type+id;
  let post_data = null;
  let aws_cache = null;
  yield
  try {
    const ec = yield checkAllJson({cache_name:ss_key});
    aws_cache = yield ec.data;
    let data_loaded = false;
    yield
    //キャッシュがある場合
    if(!!aws_cache && aws_cache[type]){
      //PUBLISH時はすぐに表示
      if(aws_cache[type].status === "publish"){
        console.log("GET_BY_ID>OK:A:publish");
        post_data = aws_cache;
        data_loaded = true;
      } else if (aws_cache[type].status === "future" && aws_cache[type].date){
        //futureの場合、時間を計算
        const future = new Date(aws_cache[type].date);
        const diff = future - new Date();
        console.log("GET_BY_ID>OK:A:future", diff);
        if(diff<=0){
          post_data = aws_cache;
          data_loaded = true;
        }
      }
    }

    //まだデータが読まれておらず、ランモードが[セーフ]ではない場合
    if(!data_loaded && window.run_mode !== 1){
      console.log("GET_BY_ID>GO:QL");
      const { data } = yield graphQL({ query, variables: { id }, key: ss_key });
      if (data && data[type]) post_data = data;
    }
  }catch(error) {
    console.log("ERROR:SAGA_GET_BYID", error);
  }

  yield
  //if (post_data && post_data[type] && post_data[type].status === "publish") {
  if (post_data && post_data[type]) {

    yield put({ type: "SET_BY_ID", payload: { id, key: `${type}ById`, val: post_data[type] } });
    sessionAction('set', ss_key, JSON.stringify(post_data));

  } else {
    yield put({ type: "ASN_FAILED", payload: { mode:'safe_mode', msg: 'お探しのページは見つかりませんでした', stop: true } })
  }

  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}

function*SAGA_POSTS_BY_TAG(action){
  if(window.run_mode===1) return;
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
  const {name, query} = action.payload;
  const session_key = encodeURIComponent("bytag"+name);
  const { data } = yield graphQL({query:query(name), variables:{name}, key:session_key});

  yield
  if(data.tags && data.tags.nodes  && data.tags.nodes.length > 0){
    yield put({type:"SET_TAG_POSTS", payload:{tag:name, posts:data.tags.nodes[0]}});
    sessionAction('set', session_key, JSON.stringify(data));
  }else {
    yield put({type:"ASN_FAILED", payload:{msg:'お探しのタグは見つかりませんでした', stop:true}})
  }
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}

function*SAGA_SEARCH(action){
  if(window.run_mode===1) return;
  const {query, endCursor, year, month,q, first=20} = action.payload;
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
  let params = {first};
  if(endCursor) params.endCursor = endCursor;
  if(year) params.year = year;
  if(month) params.month = month;
  if(q) params.q = q;

  const endCursorQuery = endCursor ? '$endCursor: String, ' : '';
  const afterQuery = endCursor ? 'after: $endCursor, ' : '';
  const yearMonthQuery = (year && month) ? '$year: Int, $month: Int, ' : '';
  const dateQuery = (year && month) ? 'dateQuery: {year: $year, month: $month}, ' : '';
  const qQuery = (q) ? '$search: String, ' : '';
  const searchQuery = (q) ? 'search: $search, ' : '';

  const variables =  {
    endCursor,
    year:parseInt(year),
    month:parseInt(month),
    first:parseInt(first),
    search:q
  };

  const ss_news_key = encodeURIComponent("search_news"+q);
  const ss_posts_key = encodeURIComponent("search_posts"+q);
  const res_news = yield graphQL({key:ss_news_key, query:query('newses', endCursorQuery, yearMonthQuery, qQuery, afterQuery, dateQuery, searchQuery), variables});
  const res_posts = yield graphQL({key:ss_posts_key, query:query('posts', endCursorQuery, yearMonthQuery, qQuery, afterQuery, dateQuery, searchQuery), variables});

  yield
  if(res_news.data || res_posts.data){
    let result = {};
    if(res_news.data) {
      result.newses = res_news.data.newses;
      sessionAction('set', ss_news_key, JSON.stringify(res_news.data));
    }

    if(res_posts.data) {
      result.posts = res_posts.data.posts;
      sessionAction('set', ss_posts_key, JSON.stringify(res_posts.data));
    }
    yield put({type:"SET_PARAM", payload:{key:'search', val:result}});
  }
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}

function*SAGA_TOP_CACHE(){
  console.log("SAGA_TOP_CACHE");
  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:true}});
  try{
    const now = new Date();
    const wp_url = window.cache_json || "/static/top_.json";
    const aws_url = `https://tshibuya-calendar-json-bucket.s3.ap-northeast-1.amazonaws.com/top_.json?state=${now.getTime()}`;
    const cache = yield checkJsonByFiles({aws_url, wp_url});
    if(!cache){
      console.log("CASHE IS NULL. SAGA_TOP_CACHE STOP HERE.");
      return;
    }

    const { data, type,  ym } = cache;
    yield
    switch(type){
      case "top":
        yield put({type:"SET_OBJ", payload:data});
        sessionAction('set', 'top', JSON.stringify(data));
        break;
      case "events":
        if(data && data.posts) {
          yield put({ type: 'SET_CAL_EVENTS', payload: { eventDate: ym, items: data.posts } });
        }
        break;
      case "newses":
        if(data && data.newses) {
          yield put({ type: 'SET_CAL_NEWSES', payload: { eventDate: ym, items: data.newses} });
        }
        break;
      default:
        break;
    }

  } catch(error){
    console.log("ERROR:SAGA_TOP_CACHE", error);
  }

  yield put({type:"SET_PARAM", payload:{key:'dbCalling', val:false}});
}


export default function* weatherWatcher() {
  yield takeLatest("SAGA_BY_EVENT_DATE",  SAGA_BY_EVENT_DATE);
  yield takeLatest("SAGA_GETS",  SAGA_GETS);
  yield takeLatest("SAGA_POSTS_BY_TAG",  SAGA_POSTS_BY_TAG);
  yield takeLatest("SAGA_GET_BYID", SAGA_GET_BYID);
  yield takeLatest("SAGA_SEARCH",  SAGA_SEARCH);
  yield takeLatest("SAGA_TOP_CACHE",  SAGA_TOP_CACHE);
}
