import {wpgq_endpoint} from '../../config'

const graphQL=async ({query, variables, key})=> {
  console.log("GQ", key);
  const headers = { "content-type": "application/json", "Authorization": "<token>"};

  if(key && !!sessionStorage.getItem(key)) {
    return {data: JSON.parse(sessionStorage.getItem(key))};
  }
  const str_body = JSON.stringify({ query, variables });
  const options = {
    "method": "POST",
    "headers": headers,
    "body":str_body
  };

  const response = await fetch(wpgq_endpoint, options);
  return response.json();
}

export {graphQL}
