import axios from 'axios'

const fetchJson=async ({cache_url})=> {
  console.log("FJ");
  const headers = { 'Accept': 'application/json', "content-type": "application/json", "Authorization": "<token>"};
  const options = { "method": "GET", "headers": headers };
  let res ={};
  if(!!cache_url){
    try{
      const response = await fetch(cache_url, options);
      console.log("fetch:JSON>ok");
      res = await response.json();
    }catch (e) {
      console.log("fetch:JSON>ERR");
      res = null;
    }
  }
  return res;
}


const axiosJson=async ({cache_url})=> {
  console.log("AXIJ");
  let res ={};
  if(!!cache_url){
    try{
      const response = await axios.get(cache_url).then(res=>res.data);
      res = await response;
      console.log("AXIJ>OK:S3");
    }catch (e) {
      console.log("AXIJ>ERR");
      res = null;
    }
  }
  return res;
}

const checkAllJson=async ({cache_name})=> {
  const now = new Date();
  const aws_url = `https://tshibuya-calendar-json-bucket.s3.ap-northeast-1.amazonaws.com/${cache_name}_.json?state=${now.getTime()}`;
  const wp_local_url = `/wp/wp-content/themes/newtower_theme/cache/${cache_name}_.json?state=${now.getTime()}`;
  let res = await axiosJson({ cache_url:aws_url });
  if(!res)  res = await fetchJson({ cache_url:wp_local_url });
  return res || {};
}

const checkJsonByFiles=async ({aws_url, wp_url})=> {
  let res = await axiosJson({ cache_url:aws_url });
  if(!res)  res = await fetchJson({ cache_url:wp_url });
  return res || {};
}

export {checkAllJson, checkJsonByFiles}
