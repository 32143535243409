import dayjs from 'dayjs'
import { uniqBy } from 'lodash'


const initialState = {
  dbCalling:false,
  newses:{},
  tousens:{},
  events:{},
  postById:{},
  newsById:{},
  blogById:{},
  tags:{},
  called:{}
};

const eG_tag=(p)=> {
  console.log("eG_Tag!!!!");
  let eventsByYear = {}
  let posts = p.edges ? p.edges : p.nodes ? p.nodes : p;
  let ev = {};

  posts && posts.forEach(post => {
    const post_ = post.node ? post.node : post.eventACF ? post : null;
    if (!post_) return;

    const {eventACF, tags,categories, ...rest} = post_
    const [year,mon, d] = eventACF.eventDate.split("-");
    const year_mon = year+"-"+mon+"-"+d;
    let month = parseInt(mon)-1
    let day = parseInt(d)-1

    if(!eventsByYear[year]) eventsByYear[year] = [...new Array(12)].map(r => []).filter(res=>res);
    if(!eventsByYear[year][month]) eventsByYear[year][month] ={}
    if(!eventsByYear[year][month][day]) eventsByYear[year][month][day] = []
    if(!ev[year_mon]) ev[year_mon] = []
    const artists = tags && tags.nodes;
    const eventFloor = categories && categories.nodes && categories.nodes[0] ? categories.nodes[0].name : null;
    ev[year_mon].push({...eventACF, ...rest, artists, year, month, day, eventFloor})
  })
  return ev
}


const eG=(p)=>{
  let posts = p.edges ? p.edges : p.nodes ? p.nodes : p;
  return posts.map(node=> {
    const {eventACF, tags, categories} = node;
    const type = eventACF ? (eventACF.kpopeventFlag ? 'kpop' : eventACF.showTvTop ? 'tv' : "") : "";
    const eventFloor = categories && categories.nodes && categories.nodes[0].name? categories.nodes[0].name : null;
    const artists = tags && tags.nodes;
    const title = !!artists &&artists.length>0 ? artists.map(art=>art.name).join(" / ") : node.title;
    let thumb = node.featuredImage && node.featuredImage.node && node.featuredImage.node.m;
    if(thumb) thumb = thumb.replace("http:", "https:");
    return {
      title,
      header:node.title,
      type,
      eventFloor,
      thumb,
      databaseId: node.databaseId,
      date: eventACF.eventDate,
      ...eventACF,
      artists
    }
  });
}


const reducer = (state = initialState, action) => {
  const {eventDate, posts, tag, id, val, key, items} = action.payload || {};

  switch (action.type) {
    case "ASN_FAILED":
      const {mode, msg, stop} = action.payload || {};
      console.log("ASN_FAILED");
      if(mode==='safe_mode'){
        return { ...state, safeMode: true };
      }

      if(stop){
        if(window.confirm(msg)){
          window.location.href = "/";
        }
      }
      return state;

    case 'SET_OBJ':
      return action.payload ? { ...state, ...action.payload } : state;

    case 'SET_PARAM':
      return { ...state, [key]:val };

    case 'DB_CALL_START':
      return { ...state, dbCalling: true };

    case 'GET_ALL_DATA':
      return { ...state, top: action.payload, dbCalling: false };

    case  'SET_TAG_POSTS':
      if(!posts || !posts.posts) return state;
      const result = eG_tag(posts.posts) || {};
      return {
        ...state,
        tags: {
          ...state.tags,
          [tag]:result,
        }
      }

    case 'SET_BY_ID':
      return {
        ...state,
        [key]:{ ...state.postById, [id]:val }
      }

    case 'SET_CAL_NEWSES':
      if(!items || !items.nodes) return state;

      let eNN = [];
      let eTN = [];
      items.nodes.forEach(node=> {
        const type = node.newsACF ? (node.newsACF.kpopnewsFlag ? 'kpop' : "") : "";

        let tousen = false;
        let tags = node.tags && node.tags.nodes && node.tags.nodes.length > 0 ? node.tags.nodes.map(res=> {
          if(res.name && res.name.indexOf('当選') !== -1){
            tousen = true;
            return null;
          }else {
            return res.name;
          }
        }).filter(res=>res) : null;

        let thumb = node.featuredImage && node.featuredImage.node && node.featuredImage.node.m;
        if(thumb) thumb = thumb.replace("http:", "https:");
        const item_ = {
          title: node.title, type, tags, tousen,
          date: dayjs(node.date).format("YYYY-MM-DD"),
          databaseId: node.databaseId,
          thumb
        };

        if(tousen){
          eTN.push(item_)
        }else {
          eNN.push(item_);
        }

        //const _ = tousen ? eTN.push(item_) :  eNN.push(item_);
        return item_;
      });

      let newses_ = state.newses;
      let tousens_ = state.tousens;
      newses_[eventDate] = newses_[eventDate] ? uniqBy([...newses_[eventDate], ...eNN], "databaseId") : eNN;
      tousens_[eventDate] = tousens_[eventDate] ? uniqBy([...tousens_[eventDate], ...eTN], "databaseId") : eTN;
      return {
        ...state,
        newses: newses_,
        tousens: tousens_
      }

    case 'SET_CAL_EVENTS':
      if(!items || !items.nodes) return state;
      let eA = eG(items);
      const year_mon = eventDate.slice(0,-1);
      let events_ = state.events;
      events_[year_mon] = events_[year_mon] ? uniqBy([...events_[year_mon], ...eA], "databaseId") : eA;
      return {
        ...state,
        events: events_
      };

    default:
      return state;
  }
};

export default reducer;
