import {all,put} from 'redux-saga/effects';
import graphWatcher from "./wpgraphql";

export default function* rootSaga() {
  try {
    yield all([
      graphWatcher()
    ]);
  } catch (error) {
    console.log("e1");
    yield put({type:"ASN_FAILED", payload:{msg:"ネットワークをご確認ください", stop:true}})
  }
}
