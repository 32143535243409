import clsx from 'clsx'
import SvgLogo from './atom/SvgLogo'
import LottieAnime from './atom/LottieAnime'

const Preloader =({show}) => {
  return <div id="preloader" className={clsx(show && 'show')} >
      <div className="logos">
        <LottieAnime />
        <SvgLogo />
      </div>
  </div>
}

export default Preloader;
