import React, { lazy, Suspense, useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import MyPreloader from "./components/common/Preloader";
import { useDispatch } from 'react-redux'
import SafeModal from './components/common/SafeModal'
const Floors = lazy(() => import('./components/pages/Floors'));
const News = lazy(() => import('./components/pages/News'));
const Top = lazy(() => import('./components/pages/Top'));
const TagByName = lazy(() => import('./components/pages/TagByName'));
const NewsById = lazy(() => import('./components/pages/NewsById'));
const PostById = lazy(() => import('./components/pages/PostById'));
const BlogById = lazy(() => import('./components/pages/BlogById'));
const Posts  = lazy(() => import('./components/pages/Posts'));
const Blog = lazy(() => import('./components/pages/Blog'));
const Kpop = lazy(() => import('./components/pages/Kpop'));
const Tv = lazy(() => import('./components/pages/Tv'));

const AppRouter = () => {
  const dispatch = useDispatch();
  useEffect(()=> {
    dispatch({type:'SAGA_TOP_CACHE'});
  }, [])

  return (
       <>
       <Suspense fallback={ <MyPreloader show={false} /> }>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/news" element={<News group="news" />} />
            <Route path="/tousen" element={<News group="tousen" />} />
            <Route path="/eventlist" element={<Posts />} />
            <Route path="/events" element={<Posts />} />
            <Route path="/events/:yearmonth" element={<Posts />} />
            <Route path="/kpop" element={<Kpop />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/floorguide" element={<Floors section='floorguide' />} />
            <Route path="/access" element={<Floors section='access' />} />
            <Route path="/towerrecordstv" element={<Tv />} />
            <Route path="/:y/:m/:d/:id" element={<PostById />} />
            <Route path="/posts/:id" element={<PostById />} />
            <Route path="/news/:y/:m/:d/:id" element={<NewsById />} />
            <Route path="/news/:id" element={<NewsById />} />
            <Route path="/blog/:y/:m/:d/:id" element={<BlogById />} />
             <Route path="/blog/:id" element={<BlogById />} />
            <Route path="/tag/:name" element={<TagByName />} />
            <Route path="/:year/:month" element={<Posts />} />
            <Route path="*" element={<Top />} />
          </Routes>
        </BrowserRouter>
       </Suspense>
         <SafeModal />
       </>
  );
};


export default AppRouter;
