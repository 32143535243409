const sessionAction=(mode, key, data)=>{
  let out;
  if(mode==='set'){
    if(key.indexOf("post_by_date")!==-1 || key.indexOf("news_by_date")!==-1){
      const c_y = parseInt(key.slice(12,16));
      const c_m = parseInt(key.slice(17,19));
      const t_y = parseInt(new Date().getFullYear());
      const t_m = parseInt(new Date().getMonth());

      if(c_y>=t_y && c_m >= t_m){
        console.log("NOT SAVE: session");
      } else {
        console.log("SAVE TO: session");
        sessionStorage.setItem(key, data);
      }
    }
  } else if(mode==='get') {
    out = sessionStorage.getItem(key);
  }
  return out;
}

export {sessionAction}
