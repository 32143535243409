import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { useEffect } from 'react'

const SafeModal=()=> {
  const dispatch = useDispatch();
  const {safeMode} = useSelector((state) =>state);
  useEffect(() => {
    console.log("runMode", window.run_mode)
  }, []);

  const backBtn=()=> {
    dispatch({type:'SET_PARAM', payload:{key:'safeMode', val:false}});
    window.history.back();
    //window.location.href = "/";
  }

  return safeMode && <div id="safe_mode_window">
        <div className="safe_mode_inner">
          <h1> +SAFE or NOT READY+ </h1>
           <p>
             まだ公開されていないページか、現在、高アクセス対策のセーフモードになっています。
             大変申し訳ございません。ご了承ください。</p>
            <Button variant="contained" onClick={backBtn}
                   style={{color:'black', backgroundColor:'#ffce10'}}>
              戻る / Back
            </Button>
        </div>
       </div>
}

export default SafeModal;
