import React from 'react';
const SvgLogo = () => {
    return (
        <div id="svg_logo_div">
            <div className="img_area fade-in-1s">
                <svg version="1.1" id="logo_svg"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 279 79" width="279" height="79">
  <g className="svg-elem-1">
    <polygon points="17.6 6.8 16.8 0 0 0 .8 6.8 5.5 6.8 9.9 42.5 17.4 42.5 13 6.8 17.6 6.8"/>
    <polygon points="93.5 35.9 87.5 35.9 86.1 24.6 92.1 24.6 91.3 18.1 85.3 18.1 83.9 5.9 89.8 5.9 89.1 0 75.6 0 80.8 42.5 94.3 42.5 93.5 35.9"/>
    <polygon points="166.7 35.9 160.8 35.9 159.4 24.6 165.3 24.6 164.5 18.1 158.6 18.1 157.1 5.9 163 5.9 162.3 0 148.8 0 154 42.5 167.5 42.5 166.7 35.9"/>
    <path d="M180.5,28.7l.8,6.3c0,.6-.1,1.2-.9,1.2h-1.8c-.6,0-1.3-.5-1.4-1.2l-3.4-27.9c0-.6,0-1.2,.9-1.2h1.8c.6,0,1.3,.5,1.4,1.2l.9,7.2h6.8l-1-8.5c-.7-4.3-1.4-5.8-5.9-5.8h-7c-3.9,0-5.6,2-5,5.8l3.8,30.9c.4,3.2,3.4,5.8,6.6,5.8h6.7c4.3,0,5-1.2,4.5-5.8l-1-8h-6.8Z"/>
    <path d="M34.2,35l-3.4-27.9c0-.6-.7-1.2-1.3-1.2h-1.5c-.9,0-1.1,.5-1,1.2l3.4,27.9c0,.6,.7,1.2,1.3,1.2h1.5c.8,0,1.1-.5,1-1.2Zm3.5,7.5h-7.6c-3.2,0-6.1-2.6-6.5-5.8l-3.8-30.9c-.6-3.7,1.1-5.8,5-5.8h7.9c3.2,0,5.2,2.6,5.6,5.8l3.8,30.9c.4,3.9-1.3,5.8-4.4,5.8Z"/>
    <path d="M202.2,35l-3.4-27.9c0-.6-.7-1.2-1.3-1.2h-1.5c-.9,0-1.1,.5-1,1.2l3.4,27.9c0,.6,.7,1.2,1.3,1.2h1.5c.8,0,1.1-.5,1-1.2Zm3.5,7.5h-7.6c-3.2,0-6.1-2.6-6.5-5.8l-3.8-30.9c-.6-3.7,1.1-5.8,5-5.8h7.9c3.2,0,5.2,2.6,5.6,5.8l3.8,30.9c.4,3.9-1.3,5.8-4.4,5.8Z"/>

  </g>
                  <g className="svg-elem-6">
    <path d="M115.2,26.4c-.5-3.5-1.7-4.9-4.2-5.4,2.1-.6,3.2-2.4,2.8-5.6l-1.2-9.7c-.4-3.2-2.4-5.8-5.5-5.8h-13.9l5.2,42.5h7.5l-2.2-18.1h2.7c.6,0,1.2,.5,1.3,1.2l2.1,17h7.4l-2-16Zm-8.5-9.6c0,.6-.2,1.2-1,1.2h-2.7l-1.5-12.2h2.6c.6,0,1.3,.5,1.4,1.2l1.2,9.8Z"/>
    <path d="M148.3,26.4c-.5-3.5-1.7-4.9-4.2-5.4,2.1-.6,3.2-2.4,2.8-5.6l-1.2-9.7c-.4-3.2-2.4-5.8-5.5-5.8h-13.9l5.2,42.5h7.5l-2.2-18.1h2.7c.6,0,1.2,.5,1.3,1.2l2.1,17h7.4l-2-16Zm-8.5-9.6c0,.6-.2,1.2-1,1.2h-2.7l-1.5-12.1h2.6c.6,0,1.3,.5,1.4,1.2l1.2,9.8Z"/>
    <path d="M231.7,26.4c-.5-3.5-1.7-4.9-4.2-5.4,2.1-.6,3.2-2.4,2.8-5.6l-1.2-9.7c-.4-3.2-2.4-5.8-5.6-5.8h-13.9l5.2,42.5h7.5l-2.2-18.1h2.7c.6,0,1.2,.5,1.3,1.2l2.1,17h7.4l-2-16Zm-8.5-9.6c0,.6-.2,1.2-1,1.2h-2.7l-1.5-12.1h2.6c.6,0,1.3,.5,1.4,1.2l1.2,9.8Z"/>
    <path d="M277.5,26.1l1.3,10.6c.4,3.9-1.2,5.8-4.4,5.8h-9.4c-2.8,0-4.2-1.2-5-5.8l-1-8.2h7.5l.8,6.5c0,.6,.7,1.2,1.4,1.2h1.5c.8,0,1-.5,.9-1.2l-.8-6.2c-.5-2.2-1.1-2.8-2.8-3.7l-5.7-2.4c-3.3-1.4-3.6-2-4.3-5.7l-1.4-11.1c-.6-3.7,1.8-5.8,5-5.8h8.2c3.2,0,5.3,2.6,5.7,5.8l1.1,9.1h-7.5l-1-7.8c0-.6-.7-1.2-1.4-1.2h-1.5c-.9,0-1,.5-.9,1.2l.9,7c.5,2.3,.3,2.1,1.9,3l7.6,3.7c2.2,.9,2.7,1.8,3.3,5.4Z"/>
    <path d="M246.9,36.1c.8,0,1-.5,.9-1.2l-3.4-27.9c0-.6-.7-1.2-1.4-1.2h-2.7l3.7,30.2h2.8Zm-9.1,6.3L232.6,0h12.4c3.2,0,6.6,2.6,7,5.8l3.8,30.9c.4,3.9-1.2,5.8-4.4,5.8h-13.6Z"/>
    <path d="M75.8,34.7L71.6,0h-7.4l4.3,35c0,.6-.2,1.2-1,1.2h-1.4c-.6,0-1.2-.5-1.3-1.2L60.5,0h-7.6l4.3,35c0,.6-.2,1.2-1,1.2h-1.8c-.6,0-1.3-.5-1.3-1.2L48.9,0h-7l4.5,36.7c.6,3.5,2.8,5.8,6.6,5.8h4.6c2.1,0,3.5-.8,4.1-2.4,1.1,1.4,2.5,2.4,4.2,2.4h4.1c4.1,0,6.6-3.3,5.9-7.8Z"/>
  </g>

  <g  className="svg-elem-9">
    <path d="M179.5,63h2.6l-1.1-8.7c0-.4-.5-.8-1-.8h-1c-.6,0-.7,.3-.6,.8l1,8.7Zm4.5,15.3l-1.3-10.9h-2.6l1.3,10.9h-4.8l-3-24.9c-.3-2.6,.7-3.9,3.4-3.9h5.4c2.2,0,3.6,1.7,3.8,3.9l3,24.9h-5.2Z"/>
    <path d="M143.4,74.4l-.9-7.1c-.3-2.4-1.2-3.3-2.9-3.7,1.4-.4,2.2-1.6,2-3.8l-.8-6.5c-.3-2.2-2.5-3.9-4.7-3.9h-8.6l3.5,28.9h9.2c2.2,0,3.4-1.3,3.1-3.9Zm-7.5-20.2l.8,6.6c0,.4-.1,.8-.7,.8h-1.8l-1-8.2h1.7c.4,0,.9,.3,1,.8Zm1.5,12.5l.8,6.6c0,.4,0,.8-.6,.8h-1.9l-1-8.2h1.8c.4,0,.8,.4,.9,.8Z"/>
    <polygon points="115.5 78.3 114.1 66.1 111.1 66.1 112.6 78.3 107.7 78.3 104.2 49.4 109.1 49.4 110.6 61.7 113.5 61.7 112 49.4 117 49.4 120.5 78.3 115.5 78.3"/>
    <polygon points="119.9 49.4 124.8 49.4 128.3 78.3 123.4 78.3 119.9 49.4"/>
    <path d="M165.8,49.4h5.3l1.6,12.8c.3,2.7-.8,3.9-3,3.9h-.4l1.5,12.1h-4.9l-1.5-12.1h-.3c-2.2,0-4.2-1.8-4.4-3.9l-1.6-12.8h4.7l1.4,11.6c0,.4,.5,.8,.9,.8h1.6c.5,0,.7-.3,.7-.8l-1.4-11.6Z"/>
    <path d="M105.2,74.4c.3,2.6-.8,3.9-3,3.9h-6.4c-2,0-3-.8-3.4-3.9l-.7-5.6h5.1l.5,4.4c0,.4,.5,.8,.9,.8h1c.5,0,.7-.4,.6-.8l-.5-4.2c-.4-1.5-.7-2-1.9-2.5l-3.9-1.7c-2.2-.9-2.6-1.3-2.9-3.9l-.9-7.6c-.3-2.6,1.2-3.9,3.4-3.9h5.6c2.2,0,3.6,1.7,3.9,3.9l.8,6.2h-5.1l-.7-5.3c0-.4-.5-.8-.9-.8h-1c-.6,0-.7,.3-.6,.8l.6,4.8c.2,1.6,.2,1.5,1.3,2l5.2,2.5c1.5,.7,2,1.2,2.2,3.7l.9,7.2Z"/>
    <path d="M150.7,73.2c0,.4,.5,.8,.9,.8h1c.5,0,.7-.3,.7-.8l-2.9-23.8h5.2l3,24.9c.3,2.7-.8,3.9-3,3.9h-5.2c-2.2,0-4.2-1.8-4.4-3.9l-3.1-24.9h4.7l2.9,23.8Z"/>
  </g>
</svg>

            </div>
        </div>
    )
}

export default SvgLogo;
