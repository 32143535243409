import React from 'react';
import Lottie from "lottie-react";
import animationData from '../../../constant/tower_logo.json';

export default function LottieAnime() {
  return (
    <div className="lottie tw-pointer-events-none">
      <Lottie animationData={animationData}
               loop={false}
               style={{width:'100%',height:'auto'}}/>
    </div>
  )
}
